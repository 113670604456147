import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './editWorkoutModal.scss';
import { Button, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import axios from 'axios';
import { API_URL } from '../../assets/constants';
import moment from 'moment';

/* IMAGES */
import HealthImg from '../../assets/images/icons/health_icon.svg';
import SickImg from '../../assets/images/icons/sick.svg';
import StretchImg from '../../assets/images/icons/stretching.svg';

/* COMPONENTS */

function EditWorkoutModal({ workout, refreshWorkout, onModalClose, onModalOpen }) {
  const [showModal, setShowModal] = useState(true);
  const [workoutName, setWorkoutName] = useState(workout.name || '');
  const [workoutDate, setWorkoutDate] = useState(workout.date || '');
  const [trainingGoal, setTrainingGoal] = useState(workout.training_goal_id || '');
  const [injury, setInjury] = useState(workout.injury || false);
  const [stretched, setStretched] = useState(workout.stretched || false);
  const [sick, setSick] = useState(workout.sick || false);
  const [trainingGoals, setTrainingGoals] = useState([]);

  useEffect(() => {
    getTrainingGoals();
  }, []);

  const getTrainingGoals = () => {
    async function getTG() {
     const response = await axios.get(API_URL + `/api/trainingGoal/list`);

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     setTrainingGoals(response.data);
    }
    getTG();    
  }

  const closeModal = () => {
    setShowModal(false);
    if (typeof onModalClose == 'function') onModalClose();
  }

  const openModal = () => {
    setShowModal(true);
    if (typeof onModalClose == 'function') onModalOpen();
  }

  const handleEditWorkoutInput = e => {
    if (e.target.name == 'workout-name') setWorkoutName(e.target.value);
    if (e.target.name == 'workout-date') setWorkoutDate(e.target.value);
  }  

  const saveWorkout = () => {
    async function save() {
     const response = await axios.put(API_URL + `/api/workouts/${workout._id}/update`, {
      name: workoutName, 
      date: moment(workoutDate).format(moment().DATETIME_LOCAL),
      injury: injury,
      stretched, stretched,
      sick, sick,
      training_goal_id: trainingGoal,
    });

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     if (typeof refreshWorkout == 'function') refreshWorkout();
     closeModal();
    }
    save();     
  }

  const handleInjuryClick = () => {
    setInjury(!injury);
  }
  const handleStretchClick = () => {
    setStretched(!stretched);
  }
  const handleSickClick = () => {
    setSick(!sick);
  }

  const handleTrainingGoalChange = e => {
    setTrainingGoal(e.target.value);
  }

  return (
    <Modal id="edit-workout-modal" show={true} onHide={onModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Workout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Control style={{textAlign: 'center'}} id="workout-name" type="text" placeholder="workout name" name="workout-name" onChange={e => handleEditWorkoutInput(e)} value={workoutName} value={workoutName} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control style={{textAlign: 'center'}} type="datetime-local" placeholder="" name="workout-date" onChange={e => handleEditWorkoutInput(e)} value={moment(workoutDate).format('YYYY-MM-DD HH:mm:ss')} />
        </Form.Group>  
        <Form.Control className="mb-3" as="select" value={trainingGoal} id="training-goal-select" onChange={handleTrainingGoalChange}>
          <option value=""></option>
          {
            trainingGoals.length > 0 &&
            trainingGoals.map(tG => (
              <option key={tG._id} value={tG._id.toString()}>{tG.name}</option>
            ))
          }
        </Form.Control>

        <div id="option-panels-container">
          <div className={injury ? 'option-panel active injury' : 'option-panel'}><Image onClick={handleInjuryClick} fluid className="icon" src={HealthImg} /><p style={{textAlign: 'center', fontSize: '10px'}}>Injured</p></div>
          <div className={stretched ? 'option-panel active stretched' : 'option-panel'}><Image onClick={handleStretchClick} fluid className="icon" src={StretchImg} /><p style={{textAlign: 'center', fontSize: '10px'}}>Stretched</p></div>
          <div className={sick ? 'option-panel active sick' : 'option-panel'}><Image onClick={handleSickClick} fluid className="icon" src={SickImg} /><p style={{textAlign: 'center', fontSize: '10px'}}>Sick</p></div>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onModalClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveWorkout}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditWorkoutModal;
