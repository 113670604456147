import { Fragment, React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './workoutLog.scss';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../assets/constants';

/* COMPONENTS */

const ExtraSets = ({ curLength, highestSet, header }) => {
  let elements = [];

  const diff = (highestSet - curLength);

  for (let i = 0; i < diff; i++) {
    if (header === true) {
      elements.push(<th key={i}>Set #{curLength + (i + 1)}</th>);
    } else {
      elements.push(<td key={i} style={{verticalAlign: 'middle', textAlign: 'center'}}></td>);
    }
  }

  return elements;
}

function WorkoutLog({ exercise, exerciseId, refresh }) {
  // STATE PARAMS
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [workoutLog, setWorkoutLog] = useState({workouts: [], highestSet: 0});

  useEffect(() => {
    if (refresh === true) refreshTable();

    refreshTable();
  }, [refresh]);

  const refreshTable = () => {
     async function ajaxPost() {
       const response = await axios.get(API_URL + `/api/workouts/${user._id}/${exerciseId}/desc`);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }

       let count = 1;
       response.data.map(w => {
        if (w.sets.length >= count) count = w.sets.length;
       })
   
       setWorkoutLog({...workoutLog, workouts: response.data, highestSet: count});
     }
     ajaxPost();      
  }

  return (
    <div className="workout-log">
      <Table bordered condensed="true" responsive id="main-table">
        <thead></thead>
        <tbody>
          {
            workoutLog.workouts.map((workout, i) => (
              <tr key={i}>
                <td style={{verticalAlign: 'middle'}}>{moment(workout.workoutDate).format('MM/DD/YYYY')}</td>
                <td>
                  <Table striped bordered condensed="true" style={{margin: '0'}} id="secondary-table">
                    <thead>
                      <tr>
                      {
                        workout.sets.map((set, sI) => (
                          <Fragment key={sI}>
                            <th>Set #{sI + 1}</th>
                            {sI + 1 === workout.sets.length && <ExtraSets curLength={sI + 1} highestSet={workoutLog.highestSet} header={true} />}
                          </Fragment>
                        ))
                      }
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      {
                        exercise.type == 'weights' &&
                        workout.sets.map((set, sI) => (
                          <Fragment key={sI}>
                            <td>{set.weight + 'x' + set.reps}</td>
                            {sI + 1 === workout.sets.length && <ExtraSets curLength={sI + 1} highestSet={workoutLog.highestSet} header={false} />}
                          </Fragment>
                        ))                          
                      }
                      {
                        exercise.type == 'cardio' && workout.duration &&
                        <Fragment key={i}>
                          <td>{(parseInt(workout.duration.hours) > 0 ? workout.duration.hours + ' hours ' : '') + (parseInt(workout.duration.minutes) > 0 ? workout.duration.minutes + ' minutes ' : '') + (parseInt(workout.duration.seconds) > 0 ? workout.duration.seconds + ' seconds' : '')}</td>
                        </Fragment>                        
                      }
                    </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
            ))                
          }
        </tbody>
      </Table>
    </div>
  );
}

export default WorkoutLog;
