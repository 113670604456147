import { React, useEffect, useState } from 'react';
import { Button, Container, Col, Form, Row } from 'react-bootstrap';
import { contactSupport } from '../../assets/apiBank';
import 'bootstrap/dist/css/bootstrap.css';
import './contactUsForm.scss';
// Components

function ContactUsForm() {
  const userObj = JSON.parse(localStorage.getItem('user'));

  // State parameters
  const [user, setUser] = useState({
    "email": userObj.email_id,
    "subject": "** TEST (BETA) from Client Portal ** - ",
    "message": "This is a test message from the client portal contact support form.",
  });

  ///////////////
  // Functions //
  ///////////////
  const handleInputChange = e => {
    setUser({
        ...user,
        [e.target.name]: e.target.value,
    });
  }

  const submitForm = e => {
    e.preventDefault();

    console.log("Submitting...", user);

    if (window.confirm("Are you sure you want to send?")) {
        contactSupport(user.email, user.subject, user.message, response => {
            console.log('response', response);
        });
    }
  }

  return (
    <div id="contact-us-form">
        <Form onSubmit={submitForm}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Subject *</Form.Label>
            <Form.Control type="subject" name="subject" placeholder="Enter subject" value={user.subject} onChange={handleInputChange} />
          </Form.Group>
          <Form.Control as="textarea" rows="10" name="message" onChange={handleInputChange} placeholder="Leave a message here" value={user.message} />

          <Button variant="primary" type="submit" style={{marginTop: '1rem'}}>
            Submit
          </Button>
        </Form>
    </div>
  );
}

export default ContactUsForm;
