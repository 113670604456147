import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './workoutDaysInARowBanner.scss';
import { Image } from 'react-bootstrap';
import { findWorkoutDaysInARow } from '../../assets/utils';
import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../assets/constants';

/* COMPONENTS */
import BlueWorriedSmiley from '../../assets/images/icons/blue-worried-smiley.svg';
import HappyStarSmiley from '../../assets/images/icons/happy-star-smiley.svg';
import HappySweatSmiley from '../../assets/images/icons/happy-sweat-smiley.svg';
import Trophy from '../../assets/images/icons/trophy.svg';
import TrophyGray from '../../assets/images/icons/trophy-gray.svg';
import WaterBottle from '../../assets/images/icons/energy-water-bottle.svg';
import WaterBottleGray from '../../assets/images/icons/energy-water-bottle-gray.svg';

const IconSection = ({daysInARow}) => {
  const elems = [];

  for (let i = 1; i <= 6; i++) {
    if (i <= daysInARow) {
      elems.push(<Image className="dir-icon" src={WaterBottle} key={i} />);
    } else {
      elems.push(<Image className="dir-icon" src={WaterBottleGray} key={i} />)
    }
  }

  elems.push(daysInARow === 7 ? <Image key={'t1'} className="dir-icon trophy-complete" src={Trophy} /> : <Image key={'t1'} className="dir-icon trophy" src={TrophyGray} />);
  return elems;
}

function WorkoutDaysInARowBanner() {
  const [workouts, setWorkouts] = useState([]);
  const [workoutDaysInARow, setWorkoutDaysInARow] = useState('');
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  useEffect(() => {
     async function getWorkouts() {
       const response = await axios.get(API_URL + `/api/workouts/list/${user._id}`);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }
   
       return response.data;       
     }
     getWorkouts().then(data => {      
      const sortedWorkouts = data.sort((a,b) => new Date(b.date) - new Date(a.date));
      const count = findWorkoutDaysInARow(sortedWorkouts);
      setWorkoutDaysInARow(count); 
     })
  }, []);

  return (
    <div id="days-in-a-row-container">
      <h1 className="text-center" style={{marginBottom: '1rem'}}>{workoutDaysInARow} Workout day{workoutDaysInARow > 1 || workoutDaysInARow === 0 ? 's' : ''} in a row <Image id="smiley-face" className="icon" src={workoutDaysInARow >= 4 ? HappyStarSmiley : (workoutDaysInARow > 0 && workoutDaysInARow <= 3 ? HappySweatSmiley : BlueWorriedSmiley)} /></h1>
      
      <div id="icon-section">
        <IconSection daysInARow={workoutDaysInARow} />
      </div>      
    </div>
  );
}

export default WorkoutDaysInARowBanner;
