import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './exerciseRating.scss';
// import { Col, Container, Row } from 'react-bootstrap';

// FONT AWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

/* COMPONENTS */

function ExerciseRating({ rating, updateExercise }) {
  const [selectedStar, setSelectedStar] = useState(parseInt(rating) || 1);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const update = ratingObj => {
    // Only update if the user is an admin (Drew)
    if (user.username !== 'drewp6023') return;

    setSelectedStar(ratingObj.rating);
    updateExercise(ratingObj);
  }

  return (
    <div className="default exercise-rating-container">
      {
        [1, 2, 3, 4, 5].map((rank, i) => (
          <FontAwesomeIcon className={rank <= selectedStar ? 'star active' : 'star'} key={i} icon={faStar} onClick={e => update({rating: rank})} />
        ))
      }
    </div>
  );
}

export default ExerciseRating;
