import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './workoutPanel.scss';
import { Card, Col, Container, Image, Row, Table, Fade } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

/* COMPONENTS */
import HealthImg from '../../assets/images/icons/health_icon.svg';

function WorkoutPanel({ workout, styles }) {
  const navigate = useNavigate();

  const goToDetails = workout => {
    navigate('/workout-details', {
      state: {
        workoutId: workout._id,
      }
    });
  }

  return (
    <>
      <div className="workout-panel" style={styles} onClick={e => goToDetails(workout)}>
        <h3>{workout.name}</h3>
        {
          workout.injury &&
          <Image className="health-icon" fluid src={HealthImg} />
        }
      </div>
      <small>{moment(workout.date).format('M/D/YYYY h:mm:ss a')}</small>
    </>
  );
}

export default WorkoutPanel;
