import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './intensityMeter.scss';
import { ProgressBar } from 'react-bootstrap';

/* COMPONENTS */

function IntensityMeter({ totalReps }) {
  return (
    <div id="intensity-meter">
      <ProgressBar className={{'max': totalReps >= 400}} now={totalReps / 400 * 100} />
    </div>
  );
}

export default IntensityMeter;
