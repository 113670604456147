import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './infiniteHorizontalScroll.scss';
// import { Col, Container, Row } from 'react-bootstrap';

/* COMPONENTS */

function InfiniteHorizontalScroll({ children }) {
  return (
    <div className="ihs__container">
      {children}
    </div>
  );
}

export default InfiniteHorizontalScroll;
