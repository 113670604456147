import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/exercises.scss';
import { Badge, Button, ButtonGroup, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import axios from 'axios';
import _ from 'underscore';
import { API_URL } from '../assets/constants';
import { capitalizeFirstLetter, scrollTo,  } from '../assets/utils';

// FONT AWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

/* COMPONENTS */
import ExerciseDetailsModal from '../components/exerciseDetailsModal/ExerciseDetailsModal';
import ExerciseTypeButtons from '../components/exerciseTypeButtons/ExerciseTypeButtons';
import PageHero from '../components/pageHero/PageHero';
import MuscleGroupButtons from '../components/muscleGroupButtons/MuscleGroupButtons';

const getDefaultExercise = () => {
  return {
    name: '',
    type: '',
    active: true,
    rating: 1,
    muscleGroups: [],
  }
}

function Exercises() {
  const [exercises, setExercises] = useState([]);
  const [exercisesByType, setExercisesByType] = useState([]);
  const [showAddExercise, setShowAddExercise] = useState(false);
  const [newExercise, setNewExercise] = useState(getDefaultExercise());
  const [showExerciseDetailsModal, setShowExerciseDetailsModal] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState({});
  const [muscleGroups, setMuscleGroups] = useState([]);
  const [muscleGroupFilter, setMuscleGroupFilter] = useState('');
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [filterStr, setFilterStr] = useState('');

  useEffect(() => {
    scrollTo('exercises-page');
    refreshExercises();
    getMuscleGroups();
  }, []);

  const MuscleSelector = ({ muscleGroups, handleClick }) => {
    return (
      muscleGroups.map((group, gI) => (
        <h5 key={gI}>
          <Badge style={{cursor: 'pointer'}} onClick={() => handleClick(group)} bg={group._id === muscleGroupFilter._id ? 'primary' : 'secondary'}>{group.name}</Badge>
        </h5>
      )) 
    )
  }

  const handleMuscleGroupFilterClick = group => {
    setMuscleGroupFilter(group._id === muscleGroupFilter._id ? '' : group);
  }

  const handleInputChange = e => {
    e.preventDefault();

    setNewExercise({...newExercise, ...{[e.target.name]: e.target.value}});
  }

  const setSelectedExerciseType = typeObj => {
    setNewExercise({...newExercise, ...{type: typeObj.type}});
  }

  const refreshExercises = callback => {
    async function getExercises() {
     const response = await axios.get(API_URL + `/api/exercises`, newExercise);

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     const exercises = response.data;
     return exercises;
    }
    getExercises()
      .then(exercises => {

        let typeArr = {};

        exercises.map(exercise => {
          if (!typeArr[exercise.type]) typeArr[exercise.type] = [];
          if (exercise.type) typeArr[exercise.type].push(exercise);
        });

        setExercisesByType(typeArr);

        setExercises(exercises);
        if (typeof callback == 'function') callback();
      });
  }

  const handleShowNewExercise = () => {
    setShowAddExercise(!showAddExercise);    
  }

  const getMuscleGroups = () => {
    async function gMG() {
     const response = await axios.get(API_URL + `/api/muscleGroup/list`);

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     const muscleGroups = response.data;
     setMuscleGroups(muscleGroups);
    }
    gMG();
  }

  const saveExercise = () => {    
    if (!newExercise.name) {
      alert("Please provide an exercise name.");
      return;
    }
    if (!newExercise.type) {
      alert("Please select an exercise type.");
      return;
    }

    // Search through the current exercises and check to see if there is already one called the same name.  If so, alert an error and return
    const match = _.findWhere(exercises, {name: newExercise.name});
    if (match) {
      alert("This is a duplicate exercise.  Please rename.");
      return;
    }

    async function addExercise() {
     const response = await axios.post(API_URL + `/api/exercises`, newExercise);

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     // const exercises = response.data;

     setShowAddExercise(false);
     refreshExercises();
     setNewExercise(getDefaultExercise());
    }
    addExercise();
  }

  const handleExerciseClick = exercise => {
    exercise.type = _.findWhere(exercises, {_id: exercise._id}).type;

    setSelectedExercise(exercise);
    setShowExerciseDetailsModal(true);
  }

  const closeModal = () => {
    setShowExerciseDetailsModal(false);
    setSelectedExercise({});
  }

  const handleMuscleGroupClick = groups => {
    setNewExercise({...newExercise, muscleGroups: groups});
  }

  const updateMuscleGroup = (name, setValueObj) => {    
    let index = newExercise.muscleGroups.findIndex(x => x.name === name);
    /* This line is only necessary if your element's ID 
       isn't its position/index in the array or related to it.
       In the case that it is, use the ID as the index, or run the function
       (binary/hash) that relates the ID to that position/index to find the 
       index.
    */
    if (index !== -1) {
      let temporaryarray = newExercise.muscleGroups.slice();

      const nA = {...temporaryarray[index], ...setValueObj};
      temporaryarray[index] = nA;

      setNewExercise({...newExercise, muscleGroups: temporaryarray});
    } else {
      console.log('no match');
    }
  }

  const removeExercise = exerciseId => {
    if (window.confirm("Are you sure you want to remove this exercise?")) {
       async function deleteExercise() {
         const response = await axios.delete(API_URL + `/api/exercises/${exerciseId}`);

         if (response.status !== 200) {
           const message = `An error occurred: ${response.statusText}`;
           window.alert(message);
           return;
         }
     
         refreshExercises();
       }
       deleteExercise();       
    }
  }

  const handleSearchFilter = e => {
    setFilterStr(e.target.value);
  }

  return (
    <div id="exercises-page">
      {showExerciseDetailsModal && <ExerciseDetailsModal exercise={selectedExercise} refresh={refreshExercises} showModal={true} closeModal={() => closeModal()} />}

      <PageHero copy="Exercises" cssOptions={{
        bgImage: localStorage.getItem('bgColor'),
      }} />

      <Container id="page-wrapper">
        <Row>
          <Col xs={12} md={6}>
            <div id="button-container">
              <Button variant="success" onClick={handleShowNewExercise}>+ Exercise</Button>
            </div>    

            {
              showAddExercise &&
              <section id="add-new-exercise-section">
                <Form>
                    <Form.Group className="mb-3">
                      <Form.Control id="workout-name" type="text" placeholder="Name" name="name" onChange={handleInputChange} value={newExercise.name} />
                    </Form.Group>

                    <section>
                      <MuscleGroupButtons muscleGroupChange={handleMuscleGroupClick} />
                    </section>

                    <section id="exercise-type-section">
                      <ExerciseTypeButtons activeType={newExercise.type} action={setSelectedExerciseType} />
                    </section>

                    <div className="d-grid gap-2">
                      <Button style={{cursor: 'pointer'}} variant="primary" size="lg" type="button" onClick={saveExercise}>Save</Button> 
                      <Button style={{cursor: 'pointer'}} variant="secondary" size="lg" type="button" onClick={handleShowNewExercise}>Close</Button> 
                    </div>              
                </Form>
              </section>     
            }            

            {muscleGroups && <div className="flexy"><MuscleSelector muscleGroups={muscleGroups} handleClick={handleMuscleGroupFilterClick} /></div>}

            <div style={{margin: '1rem 0'}}></div>

            <Form.Group>
              <Form.Control as="input" onChange={handleSearchFilter} value={filterStr} placeholder="Search.." />
            </Form.Group>            

            <div style={{margin: '1rem 0'}}></div>

            {/* EXERCISES - LIST */}
            <ListGroup>
              {
                exercises.length > 0 &&
                exercises.filter(ex => ex.name.toLowerCase().includes(filterStr.toLowerCase()) || filterStr === '').map((exercise, exerciseIndex) => (

                  !muscleGroupFilter ?
                  <ListGroup.Item key={exerciseIndex} stlye={{cursor: 'pointer'}} className="d-flex justify-content-between align-items-start" as="li">
                    <div className="ms-2 me-auto">
                      <div className="fw-bold" onClick={e => handleExerciseClick(exercise)}>{exercise.name}</div>
                    </div>            
                    {               
                      user.username == 'drewp6023' &&                         
                      <Badge bg="danger" pill>
                        <FontAwesomeIcon icon={faTrash} onClick={e => removeExercise(exercise._id)} />
                      </Badge>  
                    }
                  </ListGroup.Item>                  
                  :
                  exercise.muscleGroups.map((mgItem, mgIndex) => {
                    if (mgItem._id == muscleGroupFilter._id) {
                      return (
                        <ListGroup.Item key={exerciseIndex} stlye={{cursor: 'pointer'}} className="d-flex justify-content-between align-items-start" as="li">
                          <div className="ms-2 me-auto">
                            <div className="fw-bold" onClick={e => handleExerciseClick(exercise)}>{exercise.name}</div>
                          </div>
                          {
                            user.username == 'drewp6023' &&                                                    
                            <Badge bg="danger" pill>
                              <FontAwesomeIcon icon={faTrash} onClick={e => removeExercise(exercise._id)} />
                            </Badge>  
                          }
                        </ListGroup.Item>
                      )
                    }
                  })
                ))
              }            
            </ListGroup>
          </Col>
        </Row>
      </Container> 
    </div>
  );
}

export default Exercises;
