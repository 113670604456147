import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './exerciseHistoryChart.scss';
// import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { CartesianGrid, BarChart, Bar, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { API_URL } from '../../assets/constants';
import { getMinutes } from '../../assets/utils';

/* COMPONENTS */

function ExerciseHistoryChart({ exercise }) {
  /* STATE PARAMS */
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [historicalData, setHistoricalData] = useState('');
  const [chartData, setChartData] = useState('');

  useEffect(() => {
    console.log(exercise);
    getExerciseHistory();
  }, []);

  const getExerciseHistory = () => {
     async function gH() {
       const response = await axios.get(API_URL + `/api/workouts/${user._id}/${exercise._id || exercise.exerciseId}/asc`);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }

      // Set the chart data
      if (response.data.length > 0) {
        let newChartData = [];
        response.data.map(exercise => {
          newChartData.push({
            "name": moment(exercise.workoutDate).format('MM-DD-YYYY'),
            "type": exercise.type,
            "duration": exercise.duration,
            "xv": exercise.type == 'cardio' ? getMinutes(exercise.duration.hours, exercise.duration.minutes, exercise.duration.seconds) : (exercise.sets[0] ? exercise.sets[0].weight : ''),
          })
        });
        setChartData(newChartData);        
      }
      setHistoricalData(response.data);     
     }          
     gH();
  }

  const renderCustomizedLabel = props => {
    // console.log('props', props);
  }

  const CustomTooltip = ({ active, payload, label }) => {   
    if (active && payload && payload.length) {
      const exerciseType = payload[0].payload.type;
      const val = payload[0].payload.xv;
      const duration = payload[0].payload.duration;

      return (
        <div className="custom-tooltip" style={{backgroundColor: '#F2F2F2', padding: '0.3rem', borderRadius: '5px', border: '1px solid #ddd'}}>
          <p className="label"><u>Date</u>: {label}</p>
          <p className="label"><u>{exerciseType == 'weights' ? 'Weight' : 'Duration'}</u>: {exerciseType == 'weights' ? val + 'lbs' : (duration.hours + ':' + duration.minutes + ':' + duration.seconds)}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
    { 
      chartData !== '' &&
      <div className="exercise-history-chart">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={400} height={400} data={chartData} margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 50,
          }}>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            <XAxis dataKey="name" angle={-45} textAnchor='end' />
            <YAxis dataKey="xv" />
            <LabelList dataKey="xv" content={renderCustomizedLabel} />
            <Tooltip content={<CustomTooltip />} />
            <Bar type="monotone" radius={5} dataKey="xv" fill="#001f3f" label={{ position: 'top' }} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    }
    </> 
  );
}

export default ExerciseHistoryChart;
