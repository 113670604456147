import { React, useState, useEffect } from 'react';
import { Button, Form, Image, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/login.scss';
import logo from '../assets/images/icons/bb-blue-circle.svg';
import { getContactAffiliations } from '../assets/apiBank';
import { useNavigate } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { API_URL } from '../assets/constants';

import styled from "styled-components";
import _ from 'underscore';

/* STYLED COMPONENTS */
const UserItem = styled.div`
  text-align: center;
  cursor: pointer;
`;

// THIS BLOCK ALLOWS THE USER TO CLICK ENTER TO SUBMIT SINCE IT'S A NORMAL BUTTON AND NOT A SUBMIT BUTTON
function checkSubmit(e) {
   if(e && e.charCode == 13) {
      document.getElementById('login-submit-button').click();
   }
}

function Login({ parentMethod }) {
  // State params
  const [users, setUsers] = useState([]);  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  // Clear sessionId in parent
  useEffect(() => {
    parentMethod(''); // TODO: This is causing this to run twice.

     async function queryUsers() {
       const response = await fetch(API_URL + `/api/users`);

       if (!response.ok) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }
   
       const users = await response.json();
       
       setUsers(users);
     }    

     queryUsers();
  }, []);

  const navigate = useNavigate();

  const closeToast = event => {
    setErrorLoading(false);
    setErrorMsg('');
  }

  const handleUserAuth = (event, user) => {
    // setLoading(true);

    const uniqueId = _.uniqueId();
    parentMethod(uniqueId);

    localStorage.setItem('sessionId', uniqueId);
    localStorage.setItem('userId', user._id);
    localStorage.setItem('user', JSON.stringify(user));
    // Gray background linear-gradient(to bottom, #111111, #191717, #2a2826, #3b3936, #4b4c48)
    localStorage.setItem('bgColor', user.gradient);
    navigate('/home');
  };

  return (
    <div id="login-page">

    {
      errorLoading &&
      <ToastContainer position='top-end' className="position-fixed toast-loader">
        <Toast bg='danger'>
          <Toast.Header closeButton={true} onClick={event => closeToast()}>
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>
            {errorMsg}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    }

      <div className="flexin">
        <div id="login-container">
          <Image src={logo} style={{'width': '300px', 'display': 'block', 'margin': '0 auto 1rem'}} />
          <Form noValidate id="login-form">
            <ListGroup>
              {
                users.length > 0 &&
                users.map((user, i) => (
                  <ListGroup.Item key={i}><UserItem onClick={event => handleUserAuth(event, user)}>{user.firstName + ' ' + user.lastName}</UserItem></ListGroup.Item>  
                ))
              }
            </ListGroup>

{/*              <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="enter your email" onChange={event => setEmail(event.target.value)} onKeyPress={event => checkSubmit(event)} />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="enter your password" onChange={event => setPassword(event.target.value)} onKeyPress={event => checkSubmit(event)} />
              </Form.Group>
              <div style={{marginBottom: '1rem'}}></div>
              <Button id="login-submit-button" variant="primary" type="button" onClick={event => handleUserAuth(event)}>{loading ? 'Authenticating...' : 'Submit'}</Button>*/}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
