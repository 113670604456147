import axios from 'axios';
import qs from 'querystring';
import { API_AUTH_STR } from './constants';
import moment from 'moment';
import { logFormData } from './utils';

export const authenticate = function(username, password, callback) {
	const authStr = btoa(username + ":" + password);

	axios({
	  url: '',
	  method: "POST",
	  headers: {
	      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
	  },
	  data: qs.stringify({
	      authentication: authStr,
	  })

	})
	  .then(res => {
	    if (res.data.success) {
	    	if (typeof callback == 'function') callback(res.data);
	    } else {

	    }
	  });
}

export const contactSupport = (email, subject, message, callback) => {
    axios({
      url: '',
      method: "POST",
      headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      data: qs.stringify({
          authentication: API_AUTH_STR,
          sessionId: localStorage.getItem('sessionId'),
          method: "contactSupport",
          params: JSON.stringify({
              "email": email,
              "subject": subject,
              "message": message,
          }),
      })

    })
      .then(res => {
        if (res.data) {
            if (typeof callback == 'function') callback(JSON.parse(res.data.data));
        } else {

        }
      });
}
