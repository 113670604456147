import { React, useEffect, useState } from 'react';
import { CartesianGrid, Cell, BarChart, Bar, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import 'bootstrap/dist/css/bootstrap.css';
import './recentWorkoutVolumeChart.scss';
// import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { API_URL } from '../../assets/constants';
import _ from 'underscore';
import moment from 'moment';

/* COMPONENTS */

const sortMe = (input, rev) => {
  return rev === true ? input.sort((a,b) => new Date(a.date) - new Date(b.date)) : input.sort((a,b) => new Date(b.date) - new Date(a.date));
}

const getTotals = (workout) => {
  let output = [];
  let repTotals = 0;
  let weightTotals = 0;

  if (!workout) return;

  if (workout.exercises.length > 0) {
    workout.exercises.map(exercise => {
      if (exercise.sets.length > 0) {
        exercise.sets.map(set => {
          const weight = set.weight;
          const reps = set.reps;

          repTotals += parseInt(reps);
          weightTotals += parseInt(weight);
        })
      }
    })
  }

  return {
    reps: repTotals,
    weight: weightTotals,
    date: moment(workout.date).format("MM/DD/YYYY"),
  }
}

function RecentWorkoutVolumeChart(props) {
  /* STATE PARAMS */
  const [workouts, setWorkouts] = useState((props.workouts && sortMe(props.workouts)) || []);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [chartData, setChartData] = useState([]);
  const [selectedWorkout, setSelectedWorkout] = useState(props.selectedWorkout || {});

  useEffect(() => {
    if (!props.workouts || props.selectedWorkout) refreshWorkouts();
  }, [props.selectedWorkout]);

  const refreshWorkouts = () => {
     async function getWorkouts() {
       const response = await axios.get(API_URL + `/api/workouts/list/${user._id}`);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }

       const sortedWorkouts = sortMe(response.data);
       const currentWorkoutIndex = selectedWorkout ? _.findIndex(sortedWorkouts, {_id: selectedWorkout._id}) : {};
       // Grab the most recent workout total rep count
       let cD = [];
       const counter = props.count || 5;
       let workoutData = [];
       if (sortedWorkouts.length > 0) {
          for (let i = 0; i < counter; i++) {
            const index = (currentWorkoutIndex ? currentWorkoutIndex+i : i);

            if (!sortedWorkouts[index]) continue;

            const totals = getTotals(sortedWorkouts[index]);
            const totalReps = totals.reps;
            const totalWeight = totals.weight;
            const workoutDate = totals.date;

            cD.push({
              date: workoutDate,
              reps: totalReps,
              weight: totalWeight,
            });
          }
       }

       return {
        chartData: cD,
        workouts: sortedWorkouts,
       }
     }
     getWorkouts()
      .then(data => {
         setChartData(sortMe(data.chartData, true));
         setWorkouts(data.workouts); // sort by date property
      })     
  }

  return (
    <div id="recent-workout-volumne-chart">
      {
        chartData.length !== '' ?
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={400} height={400} data={chartData} margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 50,
          }}>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            <XAxis dataKey="date" angle={-45} textAnchor='end' />
            <YAxis dataKey="reps" />
            <Tooltip />
            <Bar type="monotone" radius={5} dataKey="reps" fill="#001f3f" label={{ position: 'top' }}>
              {
                chartData.map((entry, i) => (
                  <Cell key={`cell-${i}`} fill={i === chartData.length - 1 ? "#3D9970" : "#001f3f"}/>
                ))
              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        : <p>No chart data</p>
      }
    </div>
  );
}

export default RecentWorkoutVolumeChart;
