import { React, useEffect, useState } from 'react';
import { Card, Container, Col, Form, Image, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/profile.scss';
import axios from 'axios';
import _ from 'underscore';
import { API_URL } from '../assets/constants';
import moment from 'moment';

// IMAGES
import MaleImg from '../assets/images/icons/male_icon.svg';
import FemaleImg from '../assets/images/icons/female_icon.svg';

// Components
import PageHero from '../components/pageHero/PageHero';
import InfiniteHorizontalScroll from '../components/infiniteHorizontalScroll/InfiniteHorizontalScroll';

const ColorPanel = ({colorObj, handleClick, user}) => (
    <div className={"color-panel-container "} onClick={handleClick}>
      <div className={"color-panel " + (user.gradient == colorObj.gradient ? 'active' : '')} style={{background: colorObj.gradient}}></div>
      <p>{colorObj.name}</p>
    </div>
)

function Profile() {
  // State parameters
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [colorGradients, setColorGradients] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    refreshColorGradients();
  }, []);

  const refreshColorGradients = async () => {
    const colorGradients = await axios.get(API_URL + `/api/colorGradient/list`);
    if (colorGradients.data.length > 0) setColorGradients(colorGradients.data.sort((a,b) => a.name > b.name ? 1 : -1));
  }

  const updateUserGradient = (colorObj, callback) => {
    axios.put(API_URL + `/api/user/${user._id}/update`, {
      gradient: colorObj.gradient,
    })
      .then(() => {
        const newUserObj = {...user, gradient: colorObj.gradient};

        setUser(newUserObj);
        localStorage.setItem('user', JSON.stringify(newUserObj));
        setRefresh(true);

        // We need to reset the refresh button to false so we can re-run this function and trigger a refresh of the PageHero component
        setTimeout(() => {
          setRefresh(false);
        }, 500);

        // handleClick --> props
        if (typeof callback == 'function') callback();      
      });
  }

  return (
    <div id="profile-page">
      <PageHero copy={user.firstName + "'s " + "Profile"} refresh={refresh} cssOptions={{

      }} />

      <section id="img-section">
        <div id="wrapper">
          <div id="profile-img-container">
            <div id="profile-img"><Image fluid src={user.gender == 'female' ? FemaleImg : MaleImg} /></div>
          </div>
        </div>
        <h1 id="user-name" style={{margin: '0', textAlign: 'center'}}>~ {user.firstName + ' ' + user.lastName} ~</h1>
        <h3 id="user-name" style={{margin: '0', textAlign: 'center'}}>Birthday: {user.birthday ? moment(user.birthday).format('MM/DD/YYYY') : 'N/A'}</h3>
      </section>

      <div className="spacer"></div>

      <section id="color-panel-section">
        <Card>
          <Card.Body>
            <h1 style={{textAlign: 'center'}}>Change Background</h1>
            <hr />

            <InfiniteHorizontalScroll>
              {
                colorGradients.length > 0 &&
                colorGradients.map((colorObj, i) => (
                  <ColorPanel key={i} user={user} colorObj={colorObj} handleClick={() => updateUserGradient(colorObj)} />
                ))
              }
            </InfiniteHorizontalScroll>
          </Card.Body>
        </Card>
      </section>
    </div>
  );
}

export default Profile;
