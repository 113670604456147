import { React, useEffect, useState } from 'react';
import { CartesianGrid, Cell, BarChart, Bar, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import 'bootstrap/dist/css/bootstrap.css';
import './simpleBarChart.scss';
// import { Col, Container, Row } from 'react-bootstrap';
import { API_URL } from '../../assets/constants';
import _ from 'underscore';
import moment from 'moment';

/* COMPONENTS */

/**
 * Props:
 *  customId
 *  chartData
 *  dataKey
 *  revData
 * 
*/
function SimpleBarChart(props) {
  /* STATE PARAMS */
  return (
    <div id={props.customId ? props.customId : 'bar-chart'} className="simple-bar-chart">
      {
        props.chartData.length !== '' ?
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={400} height={400} data={props.chartData} margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 50,
          }}>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            <XAxis dataKey={props.dataKeyXAxis} angle={-45} textAnchor='end' />
            <YAxis dataKey={props.dataKeyYAxis} />
            <Tooltip />
            <Bar type="monotone" radius={5} dataKey={props.dataKeyYAxis} fill="#001f3f" label={{ position: 'top' }}>
              {
                props.chartData.map((entry, i) => (
                  <Cell key={`cell-${i}`} fill={i === props.chartData.length - 1 ? "#3D9970" : "#001f3f"}/>
                ))
              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        : <p>No chart data</p>
      }
    </div>
  );
}

export default SimpleBarChart;
