import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './durationPicker.scss';
import { Col, Container, Form, Input, InputGroup, Row, Table } from 'react-bootstrap';

/* COMPONENTS */

function DurationPicker({ data, getData }) {
  const [duration, setDuration] = useState(data || {hours: 0, minutes: 0, seconds: 0});

  const handleDurationChange = e => {
    getData({...duration, [e.target.name]: parseInt(e.target.value || 0)});
    setDuration({...duration, [e.target.name]: parseInt(e.target.value || 0)});
  }

  return (
    <div id="duration-picker">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Hours</th>
            <th>Minutes</th>
            <th>Seconds</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><Form.Control type="number" min="0" placeholder="hours" name="hours" value={duration.hours} onChange={handleDurationChange} /></td>
            <td><Form.Control type="number" min="0" placeholder="minutes" name="minutes" value={duration.minutes} onChange={handleDurationChange} /></td>
            <td><Form.Control type="number" min="0" placeholder="seconds" name="seconds" value={duration.seconds} onChange={handleDurationChange} /></td>
          </tr>
        </tbody>
      </Table>    
    </div>
  );
}

export default DurationPicker;
