import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './muscleGroupButtons.scss';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { API_URL } from '../../assets/constants';
import _ from 'underscore';

/* COMPONENTS */

function MuscleGroupButtons({ exerciseMuscleGroups, muscleGroupChange }) {
  const [muscleGroups, setMuscleGroups] = useState([]);
  const [selectedMuscleGroups, setSelectedMuscleGroups] = useState(exerciseMuscleGroups || []);

  useEffect(() => {
    refreshMuscleGroups();
  }, []);

  const refreshMuscleGroups = () => {
     async function rMG() {
       const response = await axios.get(API_URL + `/api/muscleGroup/list`);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }

       setMuscleGroups(response.data);     
     }
     rMG();
  }

  const handleMuscleGroupClick = (index, group) => {
    // If this is already in the selectedMuscleGroups, then deselect/take out of array
    let shallowCopy = [...selectedMuscleGroups];
    const alreadySelectedIndex = _.findIndex(shallowCopy, {_id: group._id});
    let newGroups = [];

    if (alreadySelectedIndex >= 0) {
      shallowCopy.splice(alreadySelectedIndex, 1);

      newGroups = shallowCopy;      
    } else {
      newGroups = [...shallowCopy, group];
    }

    setSelectedMuscleGroups(newGroups);
    muscleGroupChange(newGroups);
  }

  const handleSecondaryMGClick = (index, secondaryGroup, primaryGroup) => {
    let shallowCopy = [...selectedMuscleGroups];

    shallowCopy.map((mG, i) => {
      if (mG._id == primaryGroup._id) {
        // Found the muscle group we need to change; see if we can find the secondary group
        if (mG.secondary.length > 0) {
          mG.secondary.map(sec => {
            if (sec.name == secondaryGroup.name) {
              sec.active = !sec.active;
            }
          });
        }
      }
    })

    muscleGroupChange(shallowCopy);
  }

  return (
    <div id="muscle-group-buttons-container">
      <section className="flexy">
        {
          muscleGroups.length > 0 &&
          muscleGroups.map((group, gI) => (
            <h5 key={gI}>
              <Badge bg={_.findWhere(selectedMuscleGroups, {_id: group._id}) ? 'primary' : 'secondary'} onClick={e => handleMuscleGroupClick(gI, group)}>{group.name}</Badge>
            </h5>            
          ))
        }
      </section>

      {
        selectedMuscleGroups.length > 0 &&
        <section id="secondary-section">
          {
            selectedMuscleGroups.map((mG, mGIndex) => (
              <div key={mGIndex}>
                <p>{mG.name} - Secondary</p>
                <div className="flexy">
                  {
                    mG.secondary &&
                    mG.secondary.map((sMG, i) => (
                      <h5 key={i}>
                        <Badge bg={sMG.active ? 'primary' : 'secondary'} onClick={e => handleSecondaryMGClick(i, sMG, mG)}>{sMG.name}</Badge>
                      </h5> 
                    ))              
                  }
                </div>
                {selectedMuscleGroups.length > 1 && mGIndex !== (selectedMuscleGroups.length - 1) && <hr />}            
              </div>
            ))
          }
        </section>
      }
    </div>
  );
}

export default MuscleGroupButtons;
