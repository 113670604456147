import { React, useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './addNoteModal.scss';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { API_URL } from '../../assets/constants';
import moment from 'moment';
import { makeId } from '../../assets/utils';

/* COMPONENTS */

function AddNoteModal({ workout, note, refreshWorkout, onModalClose }) {
  /* STATE PARAMS */

  /* REFS */
  const editorRef = useRef(null);  

  const saveNote = () => {
    if (!editorRef.current) return; // Return if there is no content

    if (note) {
      // UPDATING
       async function saveNewNote() {
         const response = await axios.put(API_URL + `/api/workouts/${workout._id}/note/${note.id}`, {
            note: editorRef.current.getContent(),
            timestamp: moment(),
         });

         if (response.status !== 200) {
           const message = `An error occurred: ${response.statusText}`;
           window.alert(message);
           return;
         }
      
         if (typeof refreshWorkout == 'function') refreshWorkout(response.data);
         if (typeof onModalClose == 'function') onModalClose();
       }
       saveNewNote()
        .then(() => {
          console.log("SAVED");
        })        
    } else {
      // INSERTING
       async function saveNewNote() {
         const response = await axios.post(API_URL + `/api/workouts/${workout._id}/add_note`, {
          note: {
            id: makeId(26),
            note: editorRef.current.getContent(),
            timestamp: moment(),
          },
         });

         if (response.status !== 200) {
           const message = `An error occurred: ${response.statusText}`;
           window.alert(message);
           return;
         }
      
         if (typeof refreshWorkout == 'function') refreshWorkout(response.data);
         if (typeof onModalClose == 'function') onModalClose();
       }
       saveNewNote()
        .then(() => {
          console.log("SAVED");
        })        
    }
  }

  return (
    <Modal id="add-note-modal" show={true} onHide={onModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>{note ? 'Edit' : 'Add'} Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Editor
          apiKey='sviyxc12u0epywxmp88oem92dc61sfj65hj6tdmciqddxz1o'
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={note.note || ''}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onModalClose}>
          Close
        </Button>
        <Button variant="primary" onClick={saveNote}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddNoteModal;
