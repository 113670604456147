import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './calloutPanel.scss';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

/* COMPONENTS */

function CalloutPanel({ copy, navigateLink, goToLink, children, bgImageUrl, bgColor }) {
  const navigate = useNavigate();

  const goTo = () => {
    if (navigateLink) navigate(navigateLink);
  }

  return (
    <div className="callout-panel" onClick={goTo} style={{backgroundImage: "url(" + (bgImageUrl ? bgImageUrl : 'https://www.shutterstock.com/image-photo/stand-dumbbells-sports-fitness-room-600nw-2191119011.jpg') + ")", backgroundColor: (bgColor || '#AAAAAA')}}>
      <h1>{copy}</h1>
    </div>
  );
}

export default CalloutPanel;
