import { React, useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './exerciseTypeButtons.scss';

/* COMPONENTS */

function ExerciseTypeButtons({ activeType, action = undefined }) {
  const [activeTypeSelected, setActiveTypeSelected] = useState(activeType || '');

  const updateActiveTypeSelected = typeObj => {
    setActiveTypeSelected(typeObj.type);
    if (typeof action == 'function') action(typeObj);
  }

  return (
    <div className="exercise-type-buttons-container">
      <ButtonGroup>
        {
          ['Cardio', 'Weights'].map((type, i) => (
            <Button key={i} variant={(activeType && activeTypeSelected.toLowerCase() === type.toLowerCase()) ? 'primary' : 'secondary'} onClick={() => updateActiveTypeSelected({type: type.toLowerCase()})}>{type}</Button>
          ))
        }            
      </ButtonGroup>
    </div>
  );
}

export default ExerciseTypeButtons;
