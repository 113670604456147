import moment from 'moment';

export const getMinutes = (hours, minutes, seconds) => {
    return ((hours * 60) + (minutes + (seconds / 60))).toFixed(2)
}

export const makeId = function(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const cloneArray = function(arr) {
    const newArray = arr.map(a => ({...a}));

    return newArray;
}

export const findIndex = function(arr, prop, value) {
    const index = arr.findIndex(x => x[prop] === value);

    return index;
}

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');
  
  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return null
};

export const numberWithCommas = (x) => {
    if (!x) return 0;

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const dynamicSort = property => {
    let sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

// export const dynamicSort = property => {
//     let sortOrder = 1;

//     if(property[0] === "-") {
//         sortOrder = -1;
//         property = property.substr(1);
//     }

//     return function (a,b) {
//         /* next line works with strings and numbers, 
//          * and you may want to customize it to your needs
//          */
//         const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
//         return result * sortOrder;
//     }
// }

export const calcLoadFactor = (monthlyKwh, billingDemandKw, billingPeriod) => {
    const loadFactor = (parseFloat(monthlyKwh / (billingDemandKw * billingPeriod * 24)) * 100).toFixed(2);
    let range = '';
    let color = 'inherit';

    if (loadFactor >= 0 && loadFactor <= 39) {
        range = 'low';
        color = '#FF4136';
    } else if (loadFactor >= 40 && loadFactor <= 60) {
        range = 'medium';
        color = '#FFDC00';
    } else if (loadFactor >= 61) {
        range = 'high';
        color = '#2ECC40';
    }

    return {
        loadFactor: loadFactor,
        range: range,
        color: color,
    }
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const logFormData = formData => {
    // Display the key/value pairs
    for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
}

export const scrollTo = htmlIdentifier => {
    // var aTag = $("#" + id);
    // $('html,body').animate({scrollTop: aTag.offset().top},'slow');

    const element = document.getElementById(htmlIdentifier);
    element.scrollIntoView();
}

export const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random()
        * (max - min + 1)) + min;
};

export const random_items = (items, noOfObjects) => {
    // Use Math.random() to generate a random number between 0 and 1,
    // multiply it by the length of the array, and use Math.floor() to round down to the nearest integer

    if (parseInt(noOfObjects) > 1) {
        var result = [];
        for (var i = 0; i < noOfObjects; i++) {
            result.push(items[Math.floor(Math.random()*items.length)]);
        }
        return result;
    } else {
        return items[Math.floor(Math.random() * items.length)];
    }    
}

export const random_items_NEW = (items, noOfObjects) => {
    let arrayCopy = [...items];
    let newArray = [];
    for(let i = 0; i < noOfObjects; i++) {
        // Return if we've exhausted the amount of items in the array
        if (i+1 <= items.length) {
          let randNum = Math.floor(Math.random()*arrayCopy.length);
          let splicedItem = arrayCopy.splice(randNum, 1)[0]
          newArray.push(splicedItem);
        }
    }
    return newArray;    
}

export const findWorkoutDaysInARow = (sortedWorkouts, dateStart = moment()) => {
    // Loop through the workouts and determine how many days in a row there has been a workout
    let currentDateInLoop = dateStart ? moment(dateStart) : moment();
    let count = 0;
    let lastDateInLoop = '';
    for (let i = 0; i < 2000; i++) {
        if (i === 0) lastDateInLoop = currentDateInLoop;

        const dateAgo = i===0 ? lastDateInLoop : lastDateInLoop.subtract('1', 'days'); // Only subtract a day if we are not dealing with today
        const foundWorkout = '';

        let found = false;
        sortedWorkouts.map((workout, workoutIndex) => {
            if (moment(workout.date).format('MM-DD-YYYY') == dateAgo.format('MM-DD-YYYY')) found = true;
        });

        if (found) {
            count++;
            continue;
        } else if (i === 0 && found === false) {
            // If we don't find a workout for today and the user worked out yesterday, we can still count it as a day in a row, so continue.
            continue;
        } else {
            break;
        }
    }

    return count; 
}

export const getWeekRange = (week = 0, format='DD/MM/YYYY') => {
    var weekStart = moment().add(week, 'weeks').startOf('week');
    var days = [];
    for (var i = 0; i < 7; i++) {
        days.push(weekStart.clone().add(i, 'day').format(format));
    }
    return days;
}