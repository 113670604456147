const host = window.location.hostname;

export const ENV = (host === 'exercise.drewpickering.com' ? 'prod' : 'dev');
export const API_URL = ENV === 'prod' ? 'http://exercise.drewpickering.com:3000' : 'http://127.0.0.1:5000';
export const API_USERNAME = "";
export const API_PASSWORD = "";
export const API_AUTH_STR = btoa(API_USERNAME + ":" + API_PASSWORD);
export const GOOGLE_MAPS_API_KEY = 'AIzaSyCZrNhjxXsjtNLtT1YiVZNGqcDW4_chs9s';
export const GITLAB_KEY = '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';

// LOGGING
console.log('hostname', window.location.hostname);
console.log('ENV', ENV);
console.log('API_URL', API_URL);