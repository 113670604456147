import { React, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './newWorkoutForm.scss';
import moment from 'moment';
import axios from 'axios';
import { API_URL } from '../../assets/constants';

// COMPONENTS

function NewWorkoutForm({ show, refreshWorkouts }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [addButtonVariant, setAddButtonVariant] = useState('primary');
  const [addButtonText, setAddButtonText] = useState('Add');
  const [formData, setFormData] = useState({
    name: moment().format('MM/DD/YYYY'),
    date: '',
  });
  const navigate = useNavigate();

  const handleInputChange = e => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
  }

  const handleSubmit = () => {
    if (!formData.name) {
      alert("Please provide a workout name.");
      return;
    }
    if (!formData.date) {
      alert("Please provide a workout date.");
      return;
    }

     async function addWorkout() {
       // ADD THE USER ID FIRST
       formData.user_id = user._id;
       const response = await axios.post(API_URL + `/api/workouts`, formData);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }
   
       const workouts = response.data;

       // Call the parent to refresh the workouts
       refreshWorkouts(formData.date);
     }
     addWorkout();

    // Clear the formData
    setFormData({
      name: '',
      date: '',
    });

    setAddButtonVariant('success');
    setAddButtonText('Workout added!');

    setTimeout(() => {
      setAddButtonVariant('primary');
      setAddButtonText('Add');
    }, 2000);
  }

  const goToWorkouts = () => {
    navigate('/workouts');
  }

  return (
    <>
      {
        show ?
        <Form id="new-workout-form">
          <Form.Group className="mb-3">
            {/*<Form.Label>Name</Form.Label>*/}
            <Form.Control id="workout-name" type="text" placeholder="Name" name="name" onChange={handleInputChange} value={formData.name} />
          </Form.Group>
          <Form.Group className="mb-3">
            {/*<Form.Label>Date</Form.Label>*/}
            <Form.Control type="datetime-local" placeholder="" name="date" onChange={handleInputChange} value={formData.date} />
          </Form.Group>

          <div className="d-grid gap-2">
            <Button style={{cursor: 'pointer'}} variant={addButtonVariant} size="lg" type="button" onClick={handleSubmit}>{addButtonText}</Button> 
          </div>
        </Form>
        : <></>
      }
    </>
  );
}

export default NewWorkoutForm;
